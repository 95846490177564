<template lang="pug">
  app-input-wrapper(
    :data-type='type'
    v-bind='wrapperProps'
  )
    template(v-slot='{ inputId, hasErrors, errorId }')
      .v-input__input
        .v-input__prefix(v-if='$slots.prefix')
          slot(name='prefix')
        input.v-input__element(
          ref='input'
          :id='inputId'
          :type='type'
          :value='value'
          :maxlength='maxlength'
          :checked='checked'
          :multiple='multiple'
          :pattern='pattern'
          :title='title'
          :disabled='disabled'
          :readonly='readonly'
          :placeholder='hideLabel ? label: placeholder'
          v-a11y-required='required'
          :aria-errormessage='errorId'
          :aria-invalid='hasErrors'
          :aria-label='hideLabel ? label : null'
          v-on='overrideListeners'
        )
    template(v-slot:info)
      slot(name='info')
</template>

<script>
import AppInputWrapper, { WrapperProps, collectProps } from '@/components/app-input-wrapper'

export const InputProps = {
  ...WrapperProps,
  readonly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
  size: {
    type: [String, Number],
    default: null,
  },
  maxlength: {
    type: [String, Number],
    default: null,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  pattern: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
}

export default {
  name: 'app-input',
  components: {
    AppInputWrapper,
  },
  props: {
    ...InputProps,
    value: {
      required: false,
      default: null
    },
    checked: {
      required: false,
      default: null,
    },
    type: {
      type: String,
      default: 'text'
    },
  },
  computed: {
    wrapperProps () {
      return collectProps(this, WrapperProps)
    },
    overrideListeners () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value, event)
          if (this.$refs.input.type === 'checkbox') {
            this.$emit('update:checked', event.target.checked)
          }
        },
        keydown: event => {
          if (this.$listeners.keydown) { this.$listeners.keydown(event) }
          if (event.key === 'Enter') {
            if (this.$refs.input.type === 'text') {
              this.$emit('input', event.target.value.trim(), event)
            }
          }
        },
        blur: event => {
          if (this.$listeners.blur) { this.$listeners.blur(event) }
          if (this.$attrs['no-blur-trim'] !== undefined) return
          if (this.$refs.input.type === 'text') {
            this.$emit('input', event.target.value.trim(), event)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';

.v-input {

  &__element {
    width: 100%;
    display: inline-block;
    font-size: 0.875rem;
    padding: .3rem .5rem;
    background-clip: padding-box;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:-ms-input-placeholder {
      // Placeholder text color wasn't getting set in IE11 so text was too dark
      color: #757575;
    }

    &:disabled {
      background-color: #eee;
    }

    &[type='submit'] {
      cursor: pointer;
    }

    &[aria-invalid=true] {
      border-color: map-deep-get($base-theme, fg, error, dark);
      box-shadow: 0 0 3px map-deep-get($base-theme, fg, error, dark);
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      line-height: 1;
      min-height: 2rem;
    }
  }

  &__input {
    display: flex;
  }

  &__element {
    flex: 1 0 0;
  }

  &__prefix {
    flex: 0 0 auto;
    background-color: #eee;
    padding: 0 0.75em;
    border: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0;

    // vertical center
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    & + .v-input__element {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

}

.element[aria-invalid=true] {
  border-color: map-deep-get($base-theme, fg, error, dark);
  box-shadow: 0 0 3px map-deep-get($base-theme, fg, error, dark);
}

.label {
  font-weight: bold;
}

.errors {
  color: map-deep-get($base-theme, fg, error, dark);
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.message {
  all: unset;
}

.input {
  display: flex;
  padding-top: 0.5rem;
}
</style>
